<template>
  <div class="page-manager-asset">
    <b-card title="Cấp phát và thu hồi vật dụng">
      <div class="allocation-recall-search">
        <b-row>
          <b-col lg="4" md="4" xs="12">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Phòng ban</label>
              <v-select v-model="modelSearch.organizationBranchId" label="organizationBranchName" @input="getListDeivce" :reduce="(department) => department.id" :options="listDepartment"></v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12">
            <b-form-group>
              <label class="font-weight-bold font-size-label-custom">Vật dụng</label>
              <v-select @input="getListAllocationRecall" v-model="modelSearch.deviceId" label="type" :reduce="(type) => type.id" :options="listDeviceAll">
                <span slot="no-options" @click="$refs.select.open = false">
                    Không có dữ liệu
                  </span>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="3" md="3" xs="12" sm="3">
            <div class="acction-search-item">
              <b-button @click="getListDeivce"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
        <b-row>
          <b-col lg="12" sm="12" xs="12" md="12">
            <div class="data-table-asset">
              <span class="custom-span">Có tổng số {{totalCount}} bản ghi</span>
              <b-table :items="listAllocationRecall"
                 :fields="fieldsItem"
                 :per-page="perPage"
                 :current-page="currentPage"
                 class="mb-0">
                <template #cell(index)="data">
                  {{data.index + 1}}
                </template>
                <template #cell(fullName)="data">
                  <span v-if="data.item.fullName">{{data.item.employeeCode}} - {{data.item.fullName}}</span>
                </template>
                <template #cell(action)="data">
                  <b-button @click="updateAllcation(data.item)" v-b-tooltip.hover.top="'Cập nhật'"
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-success"
                            class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button @click="recallDevice(data.item)" v-b-tooltip.hover.top="'Thu hồi'"
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-danger"
                            class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="UserMinusIcon" />
                  </b-button>
                </template>
              </b-table>
              <b-pagination style="float: right" class="mt-2"
                v-model="currentPage"
                :per-page="perPage"
                hide-goto-end-buttons
                :total-rows="rows"
              />
            </div>
          </b-col>
        </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BButton,
  VBTooltip, BFormGroup, BRow, BCol, BTable, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import * as moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      listDepartment: [],
      listStatusAsset: [],
      modelSearch: {
        deviceType: 1,
        organizationBranchId: null,
        status: null,
        assignStatus: null,
        deviceId: null,
      },
      listAllocationRecall: [],
      listDeviceAll: [],
      perPage: 10,
      currentPage: 1,
      totalCount: 0,
      fieldsItem: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-index',
        },
        {
          key: 'type', label: 'Thiết bị', thClass: 'custom-th-type',
        },
        {
          key: 'fullName', label: 'Nhân viên', thClass: 'custom-th-employee',
        },
        {
          key: 'quantity', label: 'Sl cấp', thClass: 'custom-th-total-quantity',
        },
        {
          key: 'assignDate', label: 'Ngày cấp', thClass: 'custom-th-date',
        },
        {
          key: 'action', label: 'Hành động', thClass: 'custom-th-action', tdClass: 'custom-td-action',
        },
      ],
    }
  },
  methods: {
    getListDeivce() {
      if (this.modelSearch.organizationBranchId === null) {
        this.modelSearch.deviceId = null
        this.getListAllDevice()
        this.getListAllocationRecall()
      }
      this.$crm.post('device/find-by-condition', this.modelSearch).then(res => {
        this.listDeviceAll = res.data
        this.getListAllocationRecall()
      })
    },
    getListAllDevice() {
      this.$crm.post('device/find-by-condition', this.modelSearch).then(res => {
        this.listDeviceAll = res.data
      })
    },
    getListDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    getListAllocationRecall() {
      this.listAllocationRecall = []
      this.$crm.post('device/find-assigned-device', this.modelSearch).then(res => {
        this.listAllocationRecall = res.data
        this.totalCount = this.listAllocationRecall.length
        for (let i = 0; i < this.listAllocationRecall.length; i++) {
          this.listAllocationRecall[i].assignDate = moment(this.listAllocationRecall[i].assignDate).format('DD-MM-YYYY')
        }
      })
    },
    updateAllcation(item) {
      this.$router.push({
        name: 'update-allocation-employee',
        query: {
          idAllocation: item.id,
        },
      })
    },
    recallDevice(item) {
      this.$swal({
        title: 'Thu hồi vật dụng',
        text: `Bạn có muốn thu hồi vật dụng ${item.type} từ nhân viên ${item.fullName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.post(`device/revoke/${item.id}`).then(res => {
            this.getListAllocationRecall()
            this.$swal({
              icon: 'success',
              title: 'Thu hồi thành công!',
              text: `Thu hồi vật dụng ${item.type} từ nhân viên ${item.fullName} thành công`,
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
  },
  created() {
    this.getListDepartment()
    this.getListAllocationRecall()
    if (this.modelSearch.organizationBranchId === null) {
      this.getListAllDevice()
    }
  },
  computed: {
    rows() {
      return this.listAllocationRecall.length
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/custom/manager-item.scss';
@import 'src/@core/scss/vue/libs/vue-sweetalert.scss';
</style>
